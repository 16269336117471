import * as React from "react"
import Layout from "../components/layout"
import Blog2 from "../components/Blogs/Blog2"

const blog2 = () => {
  return (
    <Layout>
      <Blog2 />
    </Layout>
  )
}
export default blog2
